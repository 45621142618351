/* 1.1 Home Banner Swiper Started Here */
.cus-swiper-wrapper .swiper-wrapper .swiper-slide .banner-wrapper {
    height: calc(100vh - 80px);
    position: relative;
}

.cus-swiper-wrapper .swiper-wrapper .swiper-slide .banner-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cus-swiper-wrapper .swiper-wrapper .swiper-slide .banner-wrapper .banner-content-overlay {
    background: linear-gradient(90deg, #00000080 55%, #00000000);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
}

.cus-swiper-wrapper .banner-content-overlay .container {
    height: 100%;
}

.cus-swiper-wrapper .banner-content-overlay .banner-content-wrapper {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cus-swiper-wrapper .banner-content-overlay .banner-content-wrapper h4 {
    background: #ffffff2e;
    margin-top: 30px;
    width: fit-content;
    padding: 5px 8px;
}

.cus-swiper-wrapper .banner-content-overlay .banner-content-wrapper h1 {
    font-size: 70px;
    font-weight: 700;
    font-family: 'Exo 2', sans-serif;
}

.cus-swiper-wrapper .swiper-button-prev,
.cus-swiper-wrapper .swiper-button-next {
    color: var(--theme-background);
    background: #ffffff26;
    padding: 4px;
    transition: 0.4s all ease-in-out;
}

.cus-swiper-wrapper .swiper-button-prev:hover,
.cus-swiper-wrapper .swiper-button-next:hover {
    background: var(--theme-background);
    color: #fff;
}

.cus-swiper-wrapper .swiper-button-prev:after,
.cus-swiper-wrapper .swiper-rtl .swiper-button-next:after,
.cus-swiper-wrapper .swiper-button-next:after,
.cus-swiper-wrapper .swiper-rtl .swiper-button-prev:after {
    font-size: 25px;
}

/* 1.1 Home Banner Swiper Ended Here */
/* 1.2 Home Testimonials Started Here  */
.testimonials-item-wrapper {
    display: flex;
    flex-direction: column;
}

.home-testimonials-section {
    background: url(../images/background/testimonial-bg.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-testimonials-section .testimonials-content-wrapper {
    background-color: #FFF;
    color: var(--black-color);
    padding: 40px 30px 40px 50px;
    line-height: 36px;
    margin: 0px 0px 35px;
    float: left;
    display: block;
    position: relative;
}

.home-testimonials-section .testimonials-content-wrapper:after {
    content: '';
    top: 99%;
    right: 130px;
    border: solid transparent;
    height: 30px;
    width: 35px;
    position: absolute;
    background: white;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
}

.home-testimonials-section .testimonials-writer-wrapper {
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 30px;
    align-items: end;
    text-align: end;
}

.testimonials-user-img img {
    max-width: 100px;
    border: 5px solid #fff;
}

.home-testimonials-section .testimonials-writer-wrapper h2 {
    font-family: var(--theme-font);
    font-size: 23px;
    text-transform: uppercase;
}

.home-testimonials-section .testimonials-writer-wrapper h4 {
    font-size: 19px;
    font-weight: 200;
}

.home-testimonials-section .swiper-wrapper {
    padding-bottom: 70px;
}

.home-testimonials-section .swiper-pagination .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    border-radius: 0px;
    background: #fff;
}

/* 1.2 Home Testimonials Ended Here  */
/* 1.3 Home Partners Swiper Started Here */
.home-partners-section .headling-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--theme-color);
    margin-bottom: 40px;
}

.home-partners-section .headling-wrapper .common-heading {
    color: var(--theme-color);
}

.home-partners-section .headling-wrapper .common-heading:after {
    background: linear-gradient(90deg, var(--theme-color), transparent);
}

.home-partners-section .partner-item img {
    width: 90%;
    height: 115px;
    object-fit: contain;
    transition-duration: 0.3s;
}

.home-partners-section .partner-item img:hover {
    opacity: 0.6;
    filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
    /* filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg); */
}

/* 1.3 Home Partners Ended Here */
/* 1.5 Academy Achievement Started Here */
/* .academy-achievements-wrapper {
    position: relative;
} */
.academy-achievements-wrapper .swiper {
    position: unset;
}

.academy-achievements-wrapper .academy-achievement-item {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    border-radius: 10px;
    overflow: hidden;
}

.academy-achievements-wrapper .academy-achievement-item img {
    width: 100%;
    /* height: 300px; */
    aspect-ratio: 4/5;
    object-fit: contain;
}

.academy-achievements-wrapper .mySwiper {
    padding: 20px;
}

.academy-achievements-wrapper .academy-achievement-item .academy-text-wrapper {
    background: var(--theme-color);
    border-radius: 0 0 10px 10px;
    padding: 10px;
    color: #fff;
    text-align: center;
}

.academy-achievements-wrapper .academy-achievement-item .academy-text-wrapper h2 {
    font-size: 18px;
    font-weight: 300;
    font-family: var(--theme-font);
    text-transform: uppercase;
    margin-bottom: 0px;
}

.academy-achievements-wrapper .academy-achievement-item .academy-text-wrapper span {
    font-size: 15px;
    font-family: var(--theme-font);
}

.academy-achievements-wrapper .academy-achievement-item .academy-text-wrapper h3 {
    font-family: var(--heading-font);
    font-size: 28px;
}

.academy-achievements-wrapper .swiper-button-prev,
.academy-achievements-wrapper .swiper-button-next {
    background: var(--theme-color);
    color: #fff;
    padding: 4px;
    width: 40px;
    top: unset;
    bottom: 20%;
}

.academy-achievements-wrapper .swiper-button-next {
    left: 70px;
    right: unset;
}

.academy-achievements-wrapper .swiper-button-prev:after,
.academy-achievements-wrapper .swiper-rtl .swiper-button-next:after,
.academy-achievements-wrapper .swiper-button-next:after,
.academy-achievements-wrapper .swiper-rtl .swiper-button-prev:after {
    font-size: 25px;
}

.potm-item {
    background: #fff;
    padding: 0px;
}

.potm-item img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    padding: 10px;
    border-bottom: 1px solid #dbdbdb;
}

.potm-section .potm-item .potm-text-wrapper {
    padding: 10px;
}

.potm-section .potm-item .potm-text-wrapper h2 {
    color: #000;
    background: unset;
    -webkit-text-fill-color: unset;
    font-size: 23px;
    margin-top: 0px;
    text-align: center;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.potm-section .potm-item .potm-text-wrapper span {
    color: #000;
    text-align: center;
    font-size: 21px;
}

/* 1.5 Academy Achievement Ended Here */