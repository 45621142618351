.admin-main-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    padding-left: 220px;
}

.sidebar-container {
    width: 220px;
    height: 100vh;
    background-color: var(--theme-color);
    position: fixed;
    left: 0;
    top: 0;
}

.admin-right-side-wrapper {
    flex: 1;
}

.admin-logo-container {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.admin-logo-container img {
    width: 140px;
    filter: drop-shadow(0 0 5px #fff);
}

.admin-navbar-wrapper {
    width: 100%;
    height: 60px;
    background: #fff;
    box-shadow: 0 0 10px #0000004a;
    position: fixed;
    z-index: 99999;
    top: 0;
}

.admin-content-wrapper {
    padding: 15px;
    padding-top: 75px;
}

.sidebar-inner-conatainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar-inner-conatainer .sidebar-list-container {
    flex: 1;
    overflow-x: scroll;
}

.sidebar-inner-conatainer ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    padding: 10px;
    padding-bottom: 100px;
}

.sidebar-inner-conatainer ul li a {
    background: #fff;
    width: 100%;
    display: block;
    padding: 6px 25px;
    border-radius: 25px 0;
    text-decoration: none;
    color: #222;
    font-family: var(--theme-font);
    font-weight: 500;
    font-size: 15px;
    box-shadow: 0 0 10px #2d302e3b, inset -3px -4px 10px #2d302e82;
    transition: 0.3s all ease-in-out;
}

.sidebar-inner-conatainer ul li a button {
    background: #fff;
    width: 100%;
    display: block;
    padding: 6px 25px;
    border-radius: 25px 0;
    text-decoration: none;
    color: #222;
    font-family: var(--theme-font);
    font-weight: 500;
    font-size: 15px;
    box-shadow: 0 0 10px #2d302e3b, inset -3px -4px 10px #2d302e82;
    transition: 0.3s all ease-in-out;
}

.sidebar-inner-conatainer ul li a button:focus {
    box-shadow: none;
}

.sidebar-inner-conatainer ul li a.active {
    background: #f17201;
    color: #fff;
}

.admin-content-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.admin-content-container .admin-left-side {
    width: 35%;
}

.admin-content-container .admin-right-side {
    flex: 1;
}

.white-box {
    width: 100%;
    min-height: calc(100vh - 100px);
    background: #fff;
    box-shadow: 0 0 10px #00000036;
    border-radius: 9px 9px 0 0;
    border-top: 3px solid #710100;
    padding: 10px;
}

.white-box.small-white-box {
    min-height: unset;
    margin-bottom: 30px;
}

.admin-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
}

.admin-btn-container button {
    border: none;
    padding: 5px 10px;
    border-radius: 6px;
    width: 100px;
    font-family: var(--theme-font);
    font-weight: 500;
    color: #fff;
}

.form-label {
    font-family: var(--theme-font);
}

table td {
    font-family: var(--theme-font);
}

.table-reviewer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.table-reviewer-container img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.table-reviewer-container h3 {
    font-size: 20px;
    margin-bottom: 0;
}

.table-reviewer-container h4 {
    font-size: 18px;
    margin-bottom: 0;
}

.table-action-btn {
    list-style-type: none;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    justify-content: center;
    gap: 7px;
    font-size: 20px;
}

.form-label small {
    font-size: 12px;
}

.form-control {
    height: 32px;
    line-height: 18px !important;
}

.form-label {
    margin-bottom: 0.3rem !important;
}

.form-select {
    height: 32px;
    line-height: 18px !important;
}

.matches-slider-wrapper {
    position: relative;
}

.matches-slider-wrapper .container {
    position: unset;
}

.matches-slider-wrapper .swiper {
    padding-top: 50px;
}

.matches-slider-wrapper .swiper .swiper-button-prev,
.matches-slider-wrapper .swiper .swiper-rtl .swiper-button-next {
    left: unset;
    right: 60px;
    top: 26px;
    background: #fff;
    width: 40px;
    height: 40px;
}

.matches-slider-wrapper .swiper .swiper-button-next,
.matches-slider-wrapper .swiper .swiper-rtl .swiper-button-prev {
    top: 26px;
    background: #fff;
    width: 40px;
    height: 40px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    font-size: 19px;
    /* color: var(--theme-color); */
    font-weight: 600;
}

.admin-match-card {
    background: #676666;
    padding: 5px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}

.admin-match-card .table-action-btn {
    gap: 15px;
}

.admin-match-card .table-action-btn li {
    padding: 5px;
    background: #fff;
    width: 50px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-right-side .accordion-button {
    padding: 10px;
    background: #7101004f;
    color: #710100;
    font-weight: 600;
    font-family: var(--theme-font);
}

.admin-right-side .add-team-accordian .accordion-button {
    z-index: 999;
}

.admin-right-side .add-team-accordian .accordion-button button {
    z-index: 99999;
}

.admin-right-side .accordion-button button {
    background: none;
    border: none;
    color: red;
    position: absolute;
    right: 45px;
}

.admin-right-side .accordion-button:not(.collapsed) {
    background: #710100;
    color: #fff;
}

.admin-right-side .accordion-button:focus {
    box-shadow: none;
}

.accordion-item .tech-team-container .tech-team-item {
    width: calc(25% - 15px);
    position: relative;
}

.accordion-item .tech-team-container .tech-team-item span {
    background: var(--theme-color);
    color: #fff;
    position: absolute;
    z-index: 9;
    width: 30px;
    height: 30px;
    border-radius: 0 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accordion-item .tech-team-container .tech-team-item .team-img-wrapper {
    height: 160px;
}

.accordion-item .tech-team-container .tech-team-item .team-item-wrapper {
    background: #676666cc;
    padding-bottom: 46px;
}

.table-multiple-heading-container h3 {
    font-size: 20px;
}

.table-multiple-heading-container h4 {
    font-size: 14px;
    color: var(--theme-color);
}

.common-heading small {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 500;
    font-family: var(--theme-font);
}

table .dataH3 {
    font-size: 22px;
}

table .dataH4 {
    font-size: 18px;
}

.amplify-button--primary {
    background: var(--theme-color);
}



.login-text-heading {
    font-size: 23px;
    font-family: var(--theme-font);
    font-weight: 600;
    color: var(--theme-color);
    margin-bottom: 9px;
}

/* [data-amplify-router-content]>div, */
[data-amplify-authenticator] [data-amplify-router] {
    padding: 20px;
}

[data-amplify-authenticator] [data-amplify-form] {
    padding: 0px;
}

[data-amplify-authenticator][data-variation=modal] {
    /* background: url(../images/background/login.png), #0000009c; */
    background: url(../images/background/login-bg-2.png);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
    /* background: linear-gradient(#000000a1, #000000bd), url(../images/background/login.png) !important; */
}

.custom-form-control {
    display: block;
    width: 100%;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    appearance: none;
    /* border-radius: 0.375rem; */
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-form-control .react-datepicker-wrapper {
    width: 100%;
}

.custom-form-control input {
    width: 100%;
    padding: 0.2rem 0.75rem;
    border-radius: 0.375rem;
    border: 1px solid #ced4da;
    color: #212529;
}

.custom-form-control input:focus-visible {
    box-shadow: none;
    outline: none;
}

.team-behind-team-modal .modal-dialog {
    max-width: 600px !important;
}

.team-behind-team-modal.amenities-modal-container .modal-content .modal-body {
    padding: 20px;
    padding-bottom: 20px;
}

.team-behind-team-modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -70px;
}

.amenities-modal-container.team-behind-team-modal .modal-content {
    border: 1px solid var(--theme-color);
}

.team-behind-team-modal-wrapper img {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    object-fit: cover;
    border: 2px solid var(--theme-color);
    box-shadow: 0 0 10px #00000085;
    padding: 2px;
    background: #fff;
}

.team-behind-team-modal-wrapper h2 {
    font-size: 21px;
    margin-top: 8px;
    text-transform: uppercase;
    color: var(--theme-color);
    margin-bottom: 0px;
}

.team-behind-team-modal-wrapper h3 {
    font-size: 20px;
    color: #222;
}