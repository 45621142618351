.loader-main-wrapper {
    width: 100vw;
    height: 100vh;
    /* background-color: #600; */
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 8px solid #f3f3f3;
    /* Light grey */
    border-top: 8px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.gifBG2 {
    width: 100%;
    height: auto;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.gifOne {
    width: 500px;
    height: auto;
}

.app-loader-container {
    width: 100vw;
    height: 100vh;
    background-color: #000c;
    /* background-color: transparent; */
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
}