/* 1.1 Navbar Started Here */
.navbar-container {
    background: white;
    height: 75px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
    position: fixed;
    z-index: 9;
    width: 100%;
    transition: 0.4s all ease-in-out;
}

.navbar-container .header-marq-container {
    background: var(--theme-color);
    color: #fff;
    height: 30px;
    transition: 0.4s all ease-in-out;
}

.navbar-container.sticky-navbar .header-marq-container {
    display: none;
}

.navbar-container .header-marq-container marquee {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    float: right;
}

.navbar-container .header-marq-container marquee span {
    margin-right: 100px;
}

.navbar-container.sticky-navbar {
    height: 50px;
    display: flex;
}

.layout-container {
    padding-top: 75px;
}

/* .navbar-container .container {
    height: 100%;
} */

.navbar-container .navbar-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;
    height: 100%;
}

.logo-container img {
    width: 103px;
    position: absolute;
    z-index: 9;
    background: #fff;
    border-radius: 65px;
    transition: 0.4s all ease-in-out;
    top: 7px;
}

.navbar-container.sticky-navbar .logo-container img {
    width: 75px;
}

.navbar-container .social-nav-container {
    gap: 10px;
    display: flex;
    flex-direction: column;
    transition: 0.4s all ease-in-out;
    justify-content: center;
}

.navbar-container.sticky-navbar .social-nav-container {
    flex-direction: row-reverse;
    gap: 20px;
    transition: 0.4s all ease-in-out;
}

.navbar-container .social-nav-container .social-media-list li a {
    font-size: 16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: 0.4s all ease-in-out;
}

.navbar-container .social-nav-container .social-media-list li a::after {
    content: none;
}

.navbar-container .social-nav-container .social-media-list li a.footer-fb {
    background: #4267B2;
}

.navbar-container .social-nav-container .social-media-list li a.footer-insta {
    background: #bc2a8d;
}

.navbar-container .social-nav-container .social-media-list li a.footer-twitter {
    background: #1DA1F2;
}

.navbar-container .social-nav-container .social-media-list li a.footer-youtube {
    background: red;
}

.navbar-container .navbar-wrapper ul {
    list-style-type: none;
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 0px;
    align-items: center;
    transition: 0.4s all ease-in-out;
}

.navbar-container .navbar-wrapper ul.social-media-list {
    justify-content: end;
    gap: 10px;
}

.navbar-container .navbar-wrapper ul li a,
.navbar-container .navbar-wrapper ul li span {
    text-decoration: none;
    color: var(--black-color);
    font-family: var(--theme-font);
    font-size: 15px;
    transition: 0.4s all ease-in-out;
    position: relative;
    cursor: pointer;
}

.navbar-container.sticky-navbar .navbar-wrapper ul li a,
.navbar-container.sticky-navbar .navbar-wrapper ul li span {
    font-size: 16px;
    transition: 0.4s all ease-in-out;
}

.navbar-container .navbar-wrapper ul li a.wall-of-fame-btn {
    background: var(--theme-color);
    color: #fff;
    padding: 3px 10px;
    border-radius: 10px;
    border: 1px solid var(--theme-color);
}

.navbar-container .navbar-wrapper ul li a.wall-of-fame-btn::after {
    content: none;
}

.navbar-container .navbar-wrapper ul li a.wall-of-fame-btn.active::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 2px;
    background: linear-gradient(90deg, #710100, transparent);
    left: 10px;
    bottom: 3px;
    transition: 0.4s all ease-in-out;
}

.navbar-container .navbar-wrapper ul li a.wall-of-fame-btn:hover {
    color: var(--theme-color);
    background: #fff;
}

.navbar-container .navbar-wrapper ul li {
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
}

.navbar-container .navbar-wrapper ul li ul li a {
    color: #fff;
    position: relative;
}

.navbar-container .navbar-wrapper ul li a::after,
.navbar-container .navbar-wrapper ul li span::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 2px;
    background: linear-gradient(90deg, #710100, transparent);
    left: -100%;
    bottom: -2px;
    transition: 0.4s all ease-in-out;
}

.navbar-container .navbar-wrapper ul li ul li a::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 2px;
    background: linear-gradient(90deg, #fff, transparent);
    left: -100%;
    bottom: 0px;
    transition: 0.4s all ease-in-out;
}

.navbar-container .navbar-wrapper ul li a:hover::after,
.navbar-container .navbar-wrapper ul li a.active::after,
.navbar-container .navbar-wrapper ul li span.active::after,
.navbar-container .navbar-wrapper ul li span:hover:after,
.navbar-container .navbar-wrapper ul li ul li a:hover::after {
    left: 0;
}

.navbar-container .navbar-wrapper ul li a:hover,
.navbar-container .navbar-wrapper ul li a.active,
.navbar-container .navbar-wrapper ul li span.active {
    color: var(--theme-color);
    font-weight: 600;
}

.navbar-container .navbar-wrapper ul li a.wall-of-fame-btn.active {
    background: none;
    border: none;
}

.navbar-container .social-nav-container .social-media-list li a:hover {
    color: #fff;
    opacity: 0.6;
}

.navbar-container .navbar-wrapper ul li ul li a:hover,
.navbar-container .navbar-wrapper ul li ul li a.active {
    color: #fff;
}

.navbar-container .navbar-wrapper ul li ul {
    display: none;
    position: absolute;
    box-shadow: 0px 14px 16px 0px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    background: var(--theme-color);
    z-index: 2;
    padding: 10px;
    width: 230px;
    align-items: flex-start;
    gap: 5px;
    top: 70px;
    transition: 0.4s all ease-in-out;
}

.navbar-container.sticky-navbar .navbar-wrapper ul li ul {
    top: 45px;
}

.navbar-container .navbar-wrapper ul li:hover ul {
    display: flex;
}

/*  */
@keyframes playbutton1 {
    0% {
        box-shadow: 0 0 0 0 rgba(67, 5, 5, 0);
        opacity: 1;
    }

    100% {
        box-shadow: 0 0 0 20px rgba(67, 5, 5, 1);
        opacity: 0;
    }
}

@keyframes playbutton {
    0% {
        box-shadow: 0 0 0 0 rgba(67, 5, 5, 0);
        opacity: 1;
    }

    100% {
        box-shadow: 0 0 0 10px rgba(67, 5, 5, 1);
        opacity: 0;
    }
}

.navbar-container .navbar-wrapper ul li a button {
    background: var(--theme-color);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    padding: 4px 15px;
    max-width: 650px;
    position: relative;
    color: #fff;
    font-family: var(--theme-font);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.navbar-container .navbar-wrapper ul li a button::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 500px;
    animation: 2s ease 0s normal none infinite running playbutton1;
    z-index: -1;
}

.navbar-container .navbar-wrapper ul li a button::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 500px;
    animation: 2s ease 0s normal none infinite running playbutton;
    z-index: -1;
}

.navbar-container .navbar-wrapper ul li.apply-for-trial-li {
    overflow: visible;
}

.navbar-container .navbar-wrapper ul li.apply-for-trial-li a::after {
    content: none;
}

.navbar-container .navbar-wrapper ul li a button img {
    height: 25px;
}

.hamburger-btn {
    display: none;
    background: transparent;
    border: none;
    padding: 0px;
    height: auto;
    font-size: 25px;
    color: var(--theme-color);
}

.hamburger-btn svg {
    margin-top: -7px;
}

/* 1.1 Navbar Ended Here */

/* mobile view started here */
@media only screen and (max-width: 768px) {
    .hamburger-btn {
        display: block;
    }

    .navbar-container .navbar-wrapper ul {
        display: none;
        transition: 0.4s all ease-in-out;
    }

    .logo-container img,
    .navbar-container.sticky-navbar .logo-container img {
        width: 50px;
    }

    .navbar-container .navbar-wrapper ul.active {
        transition: 0.4s all ease-in-out;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background: #ffffff;
        position: absolute;
        width: 100%;
        left: 0;
        top: 50px;
        padding: 15px;
        border-radius: 0 0 10px 10px;
        box-shadow: 4px 7px 10px #0000002e;
    }

    .navbar-container .navbar-wrapper ul li {
        width: 100%;
    }

    .navbar-container .navbar-wrapper ul li a,
    container .navbar-wrapper ul li a.active,
    .navbar-container .navbar-wrapper ul li span {
        padding: 5px 10px;
        width: 100%;
        background: #e9e4e4;
    }

    .navbar-container .header-marq-container,
    .navbar-container .navbar-wrapper ul li.apply-for-trial-li {
        display: none;
    }

    .navbar-container {
        height: 50px;
    }

    .navbar-container .navbar-wrapper ul li a:hover,
    .navbar-container .navbar-wrapper ul li a.active,
    .navbar-container .navbar-wrapper ul li span.active {
        background: var(--theme-color);
        font-weight: 600;
        color: #fff;
    }

    .layout-container {
        padding-top: 50px;
    }

    .navbar-container .navbar-wrapper ul li:hover ul {
        display: none;
    }

    .navbar-container .navbar-wrapper ul li ul.activeUL {
        display: flex;
        position: relative;
        width: 100%;
        top: 0;
        background: #a36b6a;
    }

    .navbar-container .navbar-wrapper ul li {
        flex-direction: column;
    }

    .navbar-container.sticky-navbar .navbar-wrapper ul li span:hover {
        background: var(--theme-color);
        color: #fff;
    }

    .navbar-container .navbar-wrapper ul li ul.activeUL li a {
        color: var(--theme-color);
    }

    .navbar-container .navbar-wrapper ul li ul.activeUL li a.active {
        background: var(--theme-color);
        color: #fff;
    }

    .navbar-container .navbar-wrapper ul li ul li a::after {
        content: none;
    }
}

/* mobile view ended here */
@media only screen and (min-width: 769px) {
    .navbar-container .navbar-wrapper ul li.apply-for-trial-li-mob {
        display: none;
    }
}