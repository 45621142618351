 /* For mobile phones started */
 @media only screen and (max-width: 768px) {

     p {
         font-size: 16px;
     }

     .common-heading {
         font-size: 14px;
         margin-bottom: 10px;
     }

     .common-subheading {
         font-size: 18px;
         margin-bottom: 10px;
     }

     .sec-pad {
         padding: 20px 0;
     }

     footer .left-side-fixed-container,
     footer .right-side-fixed-container {
         display: none;
     }

     .cus-swiper-wrapper .swiper-wrapper .swiper-slide .banner-wrapper {
         height: 300px;
     }

     .cus-swiper-wrapper .banner-content-overlay .banner-content-wrapper h1 {
         font-size: 24px;
     }

     .cus-swiper-wrapper .banner-content-overlay .banner-content-wrapper h4 {
         font-size: 14px;
     }

     .home-about-section .container,
     .home-vision-section .container,
     .home-vision-section .vision-content-wrapper .home-vision-mission-container,
     .home-academy-section .container,
     .amenities-section .amenities-intro-container,
     .contact-section .container,
     .potm-section .container {
         flex-direction: column;
     }

     .home-about-section .about-img-wrapper,
     .home-vision-section .vision-img-wrapper,
     .home-vision-section .vision-img-wrapper img,
     .home-club-section .home-club-inner-wrapper .home-club-content-wrapper,
     .home-academy-section .home-academy-content-wrapper,
     .contact-section .contact-details-container,
     .contact-section .contact-details-container-wrapper,
     .about-values-section .about-values-left-wrapper {
         width: 100%;
     }

     .home-about-section .about-img-wrapper .large-img-wrapper {
         height: 350px;
     }

     .home-about-section .about-img-wrapper .small-img-wrapper {
         width: 60%;
         height: 250px;
         bottom: 56px;
     }

     .home-vision-section .vision-content-wrapper {
         width: 100%;
         margin-top: 20px;
     }

     .home-vision-section .vision-content-wrapper .home-vision-mission-container .home-vision-wrapper {
         border: 1px solid #a1a1a1ad;
         padding: 10px;
         border-radius: 5px;
     }


     .home-club-section .home-club-inner-wrapper .home-club-image-wrapper {
         display: none;
     }

     .home-club-section.sec-top-pad {
         padding-top: unset;
     }

     .home-why-snigmay-section {
         height: auto;
     }

     .home-why-snigmay-section .animated-ellipses {
         margin: 70px 0;
     }

     .home-why-snigmay-section .why-snigmay-container ul {
         column-count: 1;
     }

     .headling-wrapper {
         margin-bottom: 10px;
         text-align: center;
     }

     .facilities-container ul li::before {
         width: 10px;
         height: 10px;
     }

     .facilities-container ul li {
         font-size: 14px;
         padding-left: 20px;
     }

     .home-counter-sec .home-counter-item .home-counter-icon-wrapper {
         font-size: 50px;
     }

     .home-counter-sec .home-counter-item .home-counter-content-wrapper h2 {
         font-size: 30px;
     }

     .home-counter-sec .home-counter-item .home-counter-content-wrapper h3 {
         font-size: 12px;
     }

     .home-counter-sec .col-md-3.col-6 {
         margin-bottom: 15px;
     }

     .tech-team-container .tech-team-item {
         width: unset;
         flex: 1;
         min-width: 30%;
     }

     .amenities-parallex-1,
     .amenities-parallex-1.csr-parallex {
         height: 300px;
         min-height: unset;
     }

     .image-gallery-section .image-gallery-container img {
         width: unset;
         height: 150px;
         object-fit: cover;
         flex: 1;
         max-width: 46%;
     }

     .csr-intro-section .csr-intro-item {
         margin-bottom: 15px;
     }

     .contact-section .contact-form-container {
         width: 100%;
         margin-right: unset;
         z-index: 2;
         padding: 25px;
         margin-top: 20px;
     }

     .academy-trial-container .trial-content-wrapper .trials-design {
         font-size: 40px;
     }

     .academy-trial-container .trial-content-wrapper {
         padding-left: 60px;
     }

     .academy-trial-container .trial-content-wrapper .trial-content-card h3 {
         font-size: 13px;
     }

     .academy-trial-container .trial-img-wrapper {
         display: none;
     }

     .trial-content-wrapper .trial-form-container h3 {
         margin-top: 25px;
         font-size: 20px;
     }

     .trial-content-wrapper .trial-form-container form {
         width: unset;
     }

     .academy-trial-section .headling-wrapper {
         text-align: center;
     }

     footer .footer-column-wrapper h2,
     footer .footer-contact h2 {
         margin-top: 40px;
     }

     .section-banner {
         height: 230px;
     }

     .academy-teams-section .academy-team-item h2 {
         right: -43px;
         font-size: 18px;
     }

     .academy-teams-section .academy-team-item h3 {
         font-size: 12px;
     }

     .academy-coach-section .swiper-button-prev {
         left: 0;
     }

     .academy-coach-section .swiper-button-next {
         right: 0;
     }

     .academy-achievement-section .achievement-text-wrapper {
         height: auto;
     }

     .potm-section .potm-text-wrapper {
         text-align: center;
     }

     .potm-section .potm-text-wrapper h3 {
         font-size: 24px;
         line-height: 28px;
     }

     .potm-section .potm-text-wrapper h2 {
         font-size: 30px;
         line-height: 32px;
         margin-top: 15px;
     }

     .potm-section .potm-img-wrapper img {
         max-height: 230px;
         filter: drop-shadow(0 0 5px #fff);
     }

     .potm-section .potm-text-wrapper {
         padding: 15px;
         order: 2;
     }

     .academy-achievements-wrapper .swiper-button-next {
         left: unset;
         right: 0;
     }

     .aca-birthday-container {
         padding: 20px;
     }

     .potm-section {
         background-size: cover;
         overflow: hidden;
         background-position: center;
         background-repeat: no-repeat;
     }

 }

 /* For mobile phones ended */

 /* For desktop started*/
 /* @media only screen and (min-width: 769px) {} */

 /* For desktop ended */