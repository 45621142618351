@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500;600;700&family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Moul&display=swap');

:root {
    --black-color: #333;
    /* --theme-color: #710100; */
    --theme-color: #660000;
    --heading-font: 'Antonio', sans-serif;
    --theme-font: 'Exo 2', sans-serif;
    --cursice-font: 'Pacifico', cursive;
    --josefin-font: 'Josefin Sans', sans-serif;
    --trial-font: 'Moul', cursive;
}

p {
    font-size: 19px;
    white-space: pre-line;
}

.sec-pad {
    padding: 50px 0;
}

.sec-bottom-pad {
    padding-bottom: 50px;
}

.sec-top-pad {
    padding-top: 100px;
}

.common-heading {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--theme-color);
    position: relative;
    width: fit-content;
    margin-bottom: 20px;
    font-family: var(--heading-font);
}

.common-subheading {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    width: fit-content;
    margin-bottom: 20px;
    font-family: var(--theme-font);
}

.common-heading::after {
    content: '';
    position: absolute;
    width: 60%;
    height: 2.6px;
    background: linear-gradient(90deg, var(--theme-color), transparent);
    left: 0;
    bottom: -4px;
}

.home-btn-animation {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 600;
    padding: 2px;
    border: 2px solid var(--theme-color);
    font-family: monospace;
    color: var(--theme-color);
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 150px;
    text-align: center;
    background: transparent;
    border-radius: 0px;
}

.home-btn-animation::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: var(--theme-color);
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: all 0.3s linear;
}

.home-btn-animation:hover:before {
    transform: translateX(0);
    z-index: -1;
}

.home-btn-animation:hover {
    color: white;
}

/* 1.2 Home About Started Here  */
.home-about-section .container {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: flex-start;
}

.home-about-section .about-img-wrapper {
    width: 50%;
    position: relative;
}

.home-about-section .about-img-wrapper .large-img-wrapper {
    width: 80%;
    height: 600px;
}

.home-about-section .about-img-wrapper .large-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-about-section .about-img-wrapper .small-img-wrapper {
    position: absolute;
    width: 70%;
    height: 450px;
    bottom: 75px;
    right: 0px;
    border: 10px solid #fff;
}

.home-about-section .about-img-wrapper .small-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-about-section .about-content-wrapper {
    flex: 1;
}

.home-about-section .about-content-wrapper a {
    text-decoration: none;
}

.home-about-section .about-content-wrapper button {
    background: transparent;
    margin-top: 25px;
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    overflow: hidden;
    padding: 2px;
    width: 150px;
    transition: 0.4s all ease-in-out;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.home-about-section .about-content-wrapper a button:before {
    content: "";
    display: block;
    position: absolute;
    transform: translateX(-110%);
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--theme-color);
    transition: 0.4s all ease-in-out;
}

.home-about-section .about-content-wrapper a button span {
    z-index: 999;
}

.home-about-section .about-content-wrapper a:hover button:before {
    transform: translateX(-2px);
    z-index: -1;
}

.home-about-section .about-content-wrapper a:hover {
    color: #fff;
}

/* 1.2 Home About Ended Here  */
/* 1.3 Home Club Started Here */
.home-club-section.sec-top-pad {
    padding-top: 150px;
    overflow: hidden;
}

.home-club-section .home-club-inner-wrapper {
    background: #F6F5F8;
}

.home-club-section .home-club-inner-wrapper .container {
    display: flex;
    flex-direction: row;
}

.home-club-section .home-club-inner-wrapper .home-club-content-wrapper {
    width: 50%;
    padding: 30px 0;
}

.home-club-section .home-club-inner-wrapper .home-club-content-wrapper a {
    text-decoration: none;
}

.home-club-section .home-club-inner-wrapper .home-club-image-wrapper {
    width: 50%;
    background: var(--theme-color);
    position: relative;
}

.home-club-section .home-club-inner-wrapper .home-club-image-wrapper:after {
    content: '';
    position: absolute;
    width: 400px;
    height: 100%;
    background: var(--theme-color);
    right: -375px;
    top: 0;
}

.home-club-section .home-club-inner-wrapper .home-club-image-wrapper .home-club-overlay {
    background-image: url(../images/home/bg-overlap-2.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.home-club-section .home-club-inner-wrapper .home-club-image-wrapper .img-container {
    margin: -8em 0em -8em 0em;
    position: relative;
    height: 100%;
}

.home-club-section .home-club-inner-wrapper .home-club-image-wrapper .img-container img {
    /* width: 66%; */
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* 1.3 Home Club Ended Here */

/* 1.4 Home Vision Started Here */
.home-vision-section .container {
    display: flex;
    flex-direction: row;
}

.home-vision-section .vision-img-wrapper {
    width: 50%;
}

.home-vision-section .vision-img-wrapper img {
    width: 90%;
    object-fit: cover;
}

.home-vision-section .vision-content-wrapper {
    width: 50%;
}

.home-vision-section .vision-content-wrapper .home-vision-mission-container {
    display: flex;
    gap: 15px;
    flex-direction: row;
}

.home-vision-section .vision-content-wrapper .home-vision-mission-container .home-vision-wrapper {
    flex: 1;
}

.home-vision-mission-container .home-vision-wrapper span {
    font-size: 50px;
    color: var(--theme-color);
    line-height: 50px;
}

.home-vision-mission-container .home-vision-wrapper h3 {
    font-family: var(--theme-font);
}

/* 1.4 Home Vision Ended Here */
/* 1.5 Home Academy Started Here */
.home-academy-section {
    background: #f6f5f8;
    overflow: hidden;
}

.home-academy-section .container {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.home-academy-section .home-academy-content-wrapper {
    width: 55%;
}

.home-academy-section .home-academy-img-wrapper {
    flex: 1;
    position: relative;
}

.home-academy-section .home-academy-img-wrapper img {
    width: 90%;
    object-fit: cover;
}

.facilities-container ul {
    font-size: 18px;
    padding-left: 0px;
    list-style-type: none;
    column-count: 2;
}

.facilities-container ul li {
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative;
}

.facilities-container ul li::before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background: var(--theme-color);
    border-radius: 50%;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
}

.home-academy-content-wrapper a {
    text-decoration: none;
}

.home-academy-img-wrapper .home-academy-img-contact {
    background: var(--theme-color);
    color: #fff;
    padding: 15px 35px;
    width: -moz-fit-content;
    width: fit-content;
    transform: rotate(270deg) translateY(-50%);
    position: absolute;
    right: -166px;
    top: 40%;
    outline: 10px solid #f6f5f8;
}

.home-academy-img-wrapper .home-academy-img-contact h3 {
    font-size: 17px;
    font-weight: 400;
}

.home-academy-img-wrapper .home-academy-img-contact h2 {
    font-size: 27px;
    margin-bottom: 0px;
}

/* 1.5 Home Academy Ended Here */
/* 1.6 Recent Matches Started Here */
.home-recent-match-section {
    background: var(--theme-color);
}

.headling-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    margin-bottom: 40px;
}

.headling-wrapper .common-heading {
    color: #fff;
}

.headling-wrapper .common-heading:after {
    background: linear-gradient(90deg, #fff, transparent);
}

.home-recent-match-section .recent-maches-item {
    border: 1px solid #fff;
    padding: 20px 10px;
    padding-bottom: 60px;
    position: relative;
}

.home-recent-match-section .recent-maches-item h3 {
    font-size: 21px;
    color: #fff;
    text-align: center;
    font-family: var(--theme-font);
    margin-bottom: 25px;
    text-transform: uppercase;
}

.home-recent-match-section .recent-maches-item .score-team-containerr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.home-recent-match-section .recent-maches-item .score-team-containerr .team-logo-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    flex: 1;
}

.home-recent-match-section .recent-maches-item .score-team-containerr .team-logo-wrapper img {
    max-width: 80px;
}

.home-recent-match-section .recent-maches-item .score-team-containerr .team-logo-wrapper h4 {
    font-size: 14px;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home-recent-match-section .recent-maches-item .score-team-containerr .score-wrapper {
    font-size: 25px;
    font-weight: 600;
    color: var(--theme-color);
    background: #ffffffc7;
    padding: 0 7px;
    border-radius: 10px;
    min-width: 110px;
    text-align: center;
}

.home-recent-match-section .recent-maches-item h4 {
    color: #fff;
    font-family: var(--theme-font);
    font-size: 17px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 25px;
}

.home-recent-match-section .recent-maches-item .recent-match-btn {
    background: #fff;
    font-family: var(--theme-font);
    font-size: 18px;
    text-align: center;
    color: var(--theme-color);
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}

/* 1.6 Recent Matches Ended Here */
/* 1.7 Counter Started Here */
.home-counter-sec {
    background: linear-gradient(#710100b8, #710100b8), url(../images/background/counter-bg.png);
    background-size: 100%;
}

.home-counter-sec .home-counter-item {
    display: flex;
    gap: 20px;
    color: #fff;
    align-items: center;
}

.home-counter-sec .home-counter-item .home-counter-icon-wrapper {
    font-size: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-counter-sec .home-counter-item .home-counter-content-wrapper h2 {
    font-size: 40px;
    font-family: var(--theme-font);
    margin-bottom: 0px;
    display: flex;
    align-items: center;

}

.home-counter-sec .home-counter-item .home-counter-content-wrapper h3 {
    font-family: var(--theme-font);
    margin-bottom: 0px;
    font-size: 21px;
}

/* 1.7 Counter Ended Here */
/* 2. Footer Started Here */
footer {
    background: linear-gradient(#7101007a, #710100a3), url(../images/background/footer_bg.jpg);
    color: #fff;
    position: relative;
    padding-bottom: 100px !important;
}

/* footer .whatsapp-icon-container {
    background: #343434;
    width: fit-content;
    padding: 7px 13px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translate(150px, -50%);
    border-radius: 30px 0 0 30px;
    z-index: 99;
    transition: 0.4s all ease-in-out;
}

footer .whatsapp-icon-container:hover {
    transform: translate(0px, -50%);
}

footer .whatsapp-icon-container a .aba-whatsphone {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: var(--theme-font);
    font-size: 16px;
    padding-right: 33px;
}

footer .whatsapp-icon-container a img {
    width: 30px;
    height: auto;
} */
footer .right-side-fixed-container {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
}

footer .right-side-fixed-container ul.social-media-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

footer .right-side-fixed-container ul.social-media-list a {
    background: #fff;
    padding: 3px 20px 6px 10px;
    border-radius: 15px 0 0 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    transform: translateX(116px);
    transition: 0.4s all ease-in-out;
    box-shadow: 0 0 10px #00000038;
    color: #333;
}

footer .right-side-fixed-container ul.social-media-list a:hover {
    transform: translateX(0);
}

footer .right-side-fixed-container ul.social-media-list a span {
    margin-top: -2px;
    font-size: 20px;
}

footer .right-side-fixed-container ul.social-media-list a.footer-wp span {
    color: #075E54;
}

footer .right-side-fixed-container ul.social-media-list a.footer-fb span {
    color: #1877F2;
}

footer .right-side-fixed-container ul.social-media-list a.footer-insta span {
    color: #C13584;
}

footer .right-side-fixed-container ul.social-media-list a.footer-twitter span {
    color: #00acee;
}

footer .right-side-fixed-container ul.social-media-list a.footer-youtube span {
    color: #f00;
}

footer .right-side-fixed-container ul.social-media-list a.footer-linkedIn span {
    color: #0e577f;
}

footer .left-side-fixed-container {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
}

footer .left-side-fixed-container ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 0px;
}

footer .left-side-fixed-container ul li {
    background: #fff;
    padding: 4px;
    border-radius: 0 15px 15px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    transform: translateX(calc(-100% + 40px));
    transition: 0.4s all ease-in-out;
    box-shadow: 0 0 10px #00000038;
    color: #333;
    justify-content: flex-end;
    width: fit-content;
    padding-left: 30px;
}

footer .left-side-fixed-container ul li:hover {
    transform: translateX(-10px);
}

footer .left-side-fixed-container ul li span {
    width: 25px;
    height: 25px;
    background: #710100;
    color: #fff;
    border-radius: 50%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    margin-left: 10px;
    position: relative;
}

footer .left-side-fixed-container ul li span:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background: #71010087;
    left: -8px;
}

footer a {
    color: #fff;
    text-decoration: none;
}

footer a:hover {
    color: #fff;
}

footer .footer-logo-wrapper {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: -20px;
}

footer .footer-logo-wrapper img {
    height: 159px;
    width: auto;
    padding: 10px;
    /* background: #fff; */
    filter: drop-shadow(2px 4px 10px yellow);
    /* margin: auto; */
    object-fit: contain;
}

.footer-social-media-wrapper ul {
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.footer-social-media-wrapper ul li a svg {
    padding: 8px;
    font-size: 45px;
    background: #ffffff2e;
    border-radius: 4px;
    transition: 0.4s all ease-in-out;
}

.footer-social-media-wrapper ul li a:hover svg {
    background-color: #fff;
}

.footer-social-media-wrapper ul li .footer-fb:hover {
    color: #4267B2;
}

.footer-social-media-wrapper ul li .footer-insta:hover {
    color: #bc2a8d;
}

.footer-social-media-wrapper ul li .footer-twitter:hover {
    color: #1DA1F2;
}

.footer-social-media-wrapper ul li .footer-youtube:hover {
    color: red;
}

footer .footer-column-wrapper h2,
footer .footer-contact h2 {
    font-family: var(--theme-font);
    text-transform: uppercase;
    font-size: 23px;
    position: relative;
    width: fit-content;
    margin-bottom: 20px;
}

footer .footer-column-wrapper h2::after,
footer .footer-contact h2:after {
    content: '';
    position: absolute;
    width: 60%;
    height: 2px;
    background: linear-gradient(45deg, #fff, transparent);
    left: 0;
    bottom: -3px;
}

footer .footer-column-wrapper ul {
    padding-left: 0px;
    margin-bottom: 0px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 9px;
    font-size: 17px;
}

footer .footer-column-wrapper ul li {
    width: fit-content;
    position: relative;
    padding-left: 20px;
}

footer .footer-column-wrapper ul li:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    left: 0;
    bottom: 50%;
    transform: translateY(50%);
    transition: 0.4s all ease-in-out;
}

footer .footer-column-wrapper ul li:hover:after {
    transform: translateY(50%) rotate(45deg);
}

footer .footer-contact ul {
    padding-left: 0px;
    margin-bottom: 0px;
    list-style-type: none;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

footer .footer-contact ul li {
    display: flex;
    flex-direction: row;
    gap: 13px;
    align-items: center;
}

footer .footer-contact ul li svg {
    font-size: 23px;
}

footer .footer-strip {
    background: var(--theme-color);
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 5px;
}

footer .footer-strip p {
    font-size: 15px;
}

/* 2. Footer Ended Here */
/* 3. Banner Started Here */
.section-banner {
    width: 100%;
    height: 300px;
    position: relative;
}

.section-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section-banner .banner-content-wrapper {
    width: 100%;
    height: 100%;
    background: #0006;
    position: absolute;
    left: 0;
    top: 0;
}

.section-banner .banner-nav-wrapper {
    position: absolute;
    bottom: 40px;
}

.section-banner .banner-nav-wrapper ul {
    background: linear-gradient(90deg, #410606d9, transparent);
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
    gap: 15px;
    color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px;
    padding-right: 100px;
}

.section-banner h2 {
    font-family: var(--theme-font);
    color: #fff;
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 16px;
}

.section-banner .banner-nav-wrapper ul li {
    font-size: 18px;
    font-family: var(--theme-font);
}

.section-banner .banner-nav-wrapper ul li a {
    color: #fff;
    text-decoration: none;
}

/* 3. Banner Ended Here */
/* 4.1 About Vision Started Here */
.about-vision-section .headling-wrapper {
    margin-bottom: 0px;
}

.headling-wrapper.white-headline .common-heading {
    color: var(--theme-color);
}

.headling-wrapper.white-headline .common-subheading {
    color: var(--black-color);
}

.headling-wrapper.white-headline .common-heading::after {
    background: linear-gradient(90deg, var(--theme-color), transparent);
}

/* 4.1 About Vision Ended Here */
/* 4.2 About Team Started Here */
.team-item-wrapper .team-img-wrapper {
    width: 100%;
    /* height: 225px; */
    aspect-ratio: 2/2.3;
    background: #71010014;
    cursor: pointer;
}

.team-item-wrapper .team-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.team-item-wrapper .team-content-wrapper h2 {
    font-family: var(--heading-font);
    font-size: 17px;
    color: var(--theme-color);
    margin-top: 6px;
    margin-bottom: 3px;
}

.team-item-wrapper .team-content-wrapper h3 {
    font-family: var(--theme-font);
    font-size: 12px;
    margin-bottom: 0px;
}

.team-item-wrapper {
    background: #f1f1f1;
    padding: 3px;
    margin-bottom: 25px;
}

/* 4.2 About Team Ended Here */
/* 4.3 About Values Started Here */
.about-values-section {
    background: url(../images/background/values-bg.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-values-section .about-values-left-wrapper {
    width: 50%;
    height: auto;
    min-height: 400px;
    background: #fff;
    padding: 25px;
}

.about-values-section .about-values-left-wrapper ul {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.about-values-section .about-values-left-wrapper ul li {
    position: relative;
    font-size: 17px;
    padding-left: 30px;
}

.about-values-section .about-values-left-wrapper ul li::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: var(--theme-color);
    left: 8px;
    top: 15px;
    transform: translateY(-50%);
}

/* 4.3 About Values Ended Here */
/* 5.1 Academy Team Card Started Here */
.academy-teams-section .academy-team-item {
    width: 75%;
    /* height: 300px; */
    aspect-ratio: 4/5;
    position: relative;
    margin-bottom: 30px;
}

.academy-teams-section .academy-team-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.academy-teams-section .academy-team-item::before {
    content: "";
    width: 50%;
    height: 50%;
    background-color: rgb(113 1 0 / 58%);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.academy-teams-section .academy-team-item::after {
    content: "";
    width: 50%;
    height: 50%;
    background-color: rgb(113 1 0 / 58%);
    position: absolute;
    top: 50%;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.academy-teams-section .academy-team-item .team_card_hover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 300ms linear 0s;
}

.academy-teams-section .academy-team-item .team_card_hover::before {
    content: "";
    width: 50%;
    height: 50%;
    background-color: rgb(113 1 0 / 58%);
    position: absolute;
    top: 0;
    left: 50%;
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.academy-teams-section .academy-team-item .team_card_hover::after {
    content: "";
    width: 50%;
    height: 50%;
    background-color: rgb(113 1 0 / 58%);
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.academy-teams-section .academy-team-item:hover:before {
    left: 50%;
    opacity: 1;
    z-index: 1;
}

.academy-teams-section .academy-team-item:hover::after {
    top: 0;
    opacity: 1;
}

.academy-teams-section .academy-team-item:hover .team_card_hover {
    opacity: 1;
}

.academy-teams-section .academy-team-item:hover .team_card_hover::before {
    top: 50%;
    opacity: 1;
}

.academy-teams-section .academy-team-item:hover .team_card_hover::after {
    left: 0;
    opacity: 1;
}

.academy-teams-section .academy-team-item:hover .team_card_hover a {
    color: #fff;
    z-index: 1;
    text-decoration: none;
    font-family: var(--theme-font);
    font-size: 20px;
}

.academy-teams-section .academy-team-item h2 {
    color: var(--theme-color);
    position: absolute;
    right: -55px;
    top: 0;
    font-size: 24px;
    letter-spacing: 2px;
    font-family: var(--heading-font);
    text-transform: uppercase;
    writing-mode: vertical-rl;
}

.academy-teams-section .academy-team-item h3 {
    font-size: 16px;
    font-family: var(--heading-font);
    color: var(--black-color);
    text-transform: uppercase;
    margin-top: 6px;
    letter-spacing: 1px;
}

/* 5.1 Academy Team Card Ended Here */
/* 5.2 Academy Coach Slider Started Here */

.academy-coach-section .container {
    position: relative;
}

.academy-coach-section .Coaches-header-wrapper {
    margin-bottom: 30px;
}

.academy-coach-section .swiper {
    position: unset;
}

.academy-coach-section .swiper-button-prev,
.academy-coach-section .swiper-button-next {
    background: var(--theme-color);
    color: #fff;
    padding: 4px;
    width: 40px;
}

.academy-coach-section .swiper-button-prev:after,
.academy-coach-section .swiper-rtl .swiper-button-next:after,
.academy-coach-section .swiper-button-next:after,
.academy-coach-section .swiper-rtl .swiper-button-prev:after {
    font-size: 25px;
}

.academy-coach-section .swiper-button-next {
    /* top: 0; */
    right: -20px;
}

.academy-coach-section .swiper-button-prev {
    /* top: 0; */
    /* right: 56px; */
    left: -48px;
}

/* 5.2 Academy Coach Slider Ended Here */
/* 5.3 Academy Achievements Started Here */
.academy-achievement-section .container {
    position: relative;
}

.academy-achievement-section .achievement-text-wrapper {
    width: 100%;
    height: 350px;
    padding: 20px 0;
}

/* 5.3 Academy Achievements Ended Here */
/* 5.4 Academy Birthday Started Here */
.birthday-section {
    width: 100%;
    min-height: 400px;
    background: linear-gradient(#ffffff70, #ffffff66), url(../images/background/birthday-bg.png);
    background-size: cover;
    background-position: bottom;
}

.birthday-section .img-image-container {
    width: 150px;
    height: 150px;
    background: url(../images/background/birthday_profile.png);
    background-position: center center;
    background-repeat: no-repeat;
    padding: 1em;
    background-size: 170px;
}

.birthday-section .img-image-container img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.aca-birthday-container {
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    border: 4px solid #ffd70047;
    border-radius: 10px;
}

.aca-birthday-container .birthday-decoration-wrapper {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}

.aca-birthday-container .birthday-decoration-wrapper img {
    height: 45px;
}

.aca-birthday-container .birthday-img-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aca-birthday-container .birthday-text-wrapper {
    flex: 1;
    /* background: linear-gradient(270deg, #7101002e, transparent); */
    display: flex;
    align-items: center;
    flex-direction: column;
    /* padding-top: 100px; */
}

.aca-birthday-container .birthday-text-wrapper h1 {
    font-family: var(--cursice-font);
    font-size: 21px;
    color: var(--theme-color);
    text-align: center;
    line-height: 28px;
}

.aca-birthday-container .birthday-text-wrapper h2 {
    text-transform: uppercase;
    font-family: var(--heading-font);
    background: linear-gradient(to right, #00a4bb 10%, #ff0d00 40%, #00cf53 60%, #ff8d00 100%);
    /* background: linear-gradient( to right, #ffffff 10%, #ffdca8 40%, var(--theme-color) 60%, #f3e6bf 100% ); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 4s linear infinite;
    background-size: 200% auto;
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.aca-birthday-container .birthday-text-wrapper h3 {
    font-size: 12px;
    color: var(--theme-color);
    margin-top: 8px;
}

@keyframes shine {
    100% {
        background-position: 200% center;
    }
}

/* 5.4 Academy Birthday Ended Here */
/* 5.5 Academy POTM started Here */
.potm-section {
    width: 100%;
    min-height: 500px;
    background-image: linear-gradient(#00000085, #0000004d), url(../images/background/potm-bg.jpg);
    position: relative;
    background-size: 100%;
    overflow: hidden;
    background-position: center;
}

.potm-section.cotm-section {
    background-image: linear-gradient(rgba(69, 42, 79, 0.82), rgba(69, 42, 79, 0.82)), url(../images/background/potm-bg.jpg);
}

.potm-section .swiper-horizontal {
    width: 100%;
}

.potm-section .banner-shape {
    position: absolute;
    top: 0;
    left: 14%;
    width: 1px;
    height: 100%;
    background: rgba(255, 255, 255, 0.102);
}

.banner-shape.shape-2 {
    left: 30%;
}

.banner-shape.shape-3 {
    left: 42%;
}

.banner-shape.shape-4 {
    left: 56%;
}

.banner-shape.shape-5 {
    left: 70%;
}

.banner-shape.shape-6 {
    left: 85%;
}

.banner-shape::before {
    background: var(--theme-color);
}

.banner-shape::after {
    background: #ffffff;
}

.banner-shape::after,
.banner-shape::before {
    width: 1px;
    height: 30px;
    position: absolute;
    content: '';
}

.banner-shape:nth-of-type(odd)::after {
    animation: 15s linear utha_nama infinite;
    -webkit-animation: 15s linear utha_nama infinite;
    -moz-animation: 15s linear utha_nama infinite;
}

.banner-shape:nth-of-type(odd)::before {
    animation: 13s linear nama_utha infinite;
    -webkit-animation: 13s linear nama_utha infinite;
    -moz-animation: 13s linear nama_utha infinite;
}

.banner-shape:nth-of-type(even)::before {
    animation: 13s linear utha_nama infinite;
    -webkit-animation: 13s linear utha_nama infinite;
    -moz-animation: 13s linear utha_nama infinite;
}

.banner-shape:nth-of-type(even)::after {
    animation: 15s linear nama_utha infinite;
    -webkit-animation: 15s linear nama_utha infinite;
    -moz-animation: 15s linear nama_utha infinite;
}

@keyframes nama_utha {
    0% {
        bottom: 0;
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
    }

    100% {
        bottom: 100%;
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@keyframes utha_nama {
    0% {
        top: 0;
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    100% {
        top: 100%;
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
    }
}

.potm-section .container {
    display: flex;
    gap: 20px;
}

.potm-section .potm-text-wrapper {
    flex: 1;
    color: #fff;
    font-family: var(--josefin-font);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    z-index: 1;
}

.potm-section .potm-img-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.potm-section .potm-img-wrapper img {
    max-height: 500px;
}

.potm-section .potm-text-wrapper h3 {
    text-transform: capitalize;
    font-size: 60px;
    line-height: 80px;
}

.potm-section .potm-text-wrapper h3 span {
    color: #e9372b;
    text-transform: uppercase;
}

.potm-section .potm-text-wrapper h2 {
    font-size: 68px;
    text-transform: uppercase;
    background: linear-gradient(to right, #710100 10%, #ec6760 40%, #ffffff 60%, #f2b160 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 4s linear infinite;
    background-size: 200% auto;
    margin-top: 45px;
}

/* 5.5 Academy POTM Ended Here */
/* 5.6 Academy Trial Started Here */
.academy-trial-section .academy-trial-container {
    border: 1px solid #ddd;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.academy-trial-container .trial-content-wrapper {
    flex: 1;
    position: relative;
    /* background: linear-gradient(90deg, #f1f1f1, transparent); */
    padding-left: 130px;
}

.academy-trial-container .trial-img-wrapper {
    flex: 1;
}

.academy-trial-container .trial-img-wrapper img {
    width: 100%;
    height: 550px;
    object-fit: contain;
}

.academy-trial-container .trial-content-wrapper .trials-design {
    font-size: 85px;
    text-transform: uppercase;
    font-family: var(--trial-font);
    letter-spacing: 15px;
    -webkit-text-stroke: 3px black;
    color: transparent;
    writing-mode: vertical-rl;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    margin: 0;
}

.academy-trial-container .trial-content-wrapper .trial-content-card {
    padding-top: 40px;
}

.academy-trial-container .trial-content-wrapper .trial-content-card h3 {
    font-size: 20px;
    font-family: var(--theme-font);
    text-transform: uppercase;
}

.academy-trial-container .trial-content-wrapper .trial-content-card h3 span {
    background: var(--theme-color);
    color: #fff;
    padding: 0 10px;
    margin-right: 10px;
}

.trial-content-wrapper .trial-form-container h3 {
    font-size: 25px;
    font-family: var(--theme-font);
    text-transform: uppercase;
    background: var(--theme-color);
    color: #fff;
    padding: 2px 10px;
    margin-right: 10px;
    width: fit-content;
    margin-top: 40px;
    margin-bottom: 0px;
}

.trial-content-wrapper .trial-form-container form {
    background: #f1f1f1;
    padding: 10px;
    padding-top: 20px;
    width: 500px;
}

.trial-content-wrapper .trial-form-container form .form-control {
    border-radius: 0px;
}

/* 5.6 Academy Trial Ended Here */

/* 5.7 Academy Score Started Here */
.academy-score-section {
    background-image: linear-gradient(#00000085, #0000004d), url(../images//background/score-bg.jpg);
}

.academy-score-section .academy-score-container {
    border: 1px solid #fff;
    padding: 10px;
}

.academy-score-section .academy-score-container .accordion {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.academy-score-section .academy-score-container .accordion .accordion-item {
    border-radius: 0px;
    background: #ffffffa8;
}

.academy-score-section .academy-score-container .accordion .accordion-item .accordion-header {
    background: transparent;
}

.academy-score-section .academy-score-container .accordion-button {
    background: transparent;
}

.academy-score-section .academy-score-container .accordion .accordion-button:not(.collapsed) {
    background: #d9d2ef;
}

.academy-score-section .academy-score-container .accordion .accordion-button:focus {
    box-shadow: none;
}

.academy-score-section .academy-score-container .score-accordian-header {
    display: flex;
    flex-direction: row;
    width: 97%;
    justify-content: space-between;
    gap: 30px;
}

.academy-score-section .academy-score-container .score-accordian-header .score-accordian-h-data {
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.score-accordian-header .score-accordian-h-data .team-logo-wrapper img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.score-accordian-header .score-accordian-h-data .team-logo-wrapper {
    width: 80px;
    text-align: center;
}

.score-accordian-header .score-accordian-h-data .team-name-wrapper {
    font-size: 25px;
    font-family: var(--heading-font);
    color: var(--black-color);
    flex: 1;
}

.score-accordian-header .score-accordian-h-data .team-goal {
    font-size: 36px;
    font-family: var(--theme-font);
    color: var(--black-color);
    width: 100px;
    text-align: center;
}

.score-accordian-header span {
    display: flex;
    align-items: center;
    color: var(--black-color);
    font-size: 30px;
}

.score-accordian-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 100px;
}

.score-accordian-body .score-accordian-info-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.score-accordian-body .score-accordian-info {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    font-size: 19px;
    font-family: var(--theme-font);
    color: var(--black-color);
}

.score-accordian-body .score-accordian-info-container::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    border-right: 3px dashed #2119;
    right: -51px;
}

.score-accordian-body .score-accordian-info .player-activity-info {
    font-size: 35px;
    color: var(--theme-color);
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.score-accordian-body .score-accordian-info .player-activity-wrapper {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.score-accordian-body .score-accordian-info .player-name-wrapper {
    flex: 1;
}

.red-card {
    width: 25px;
    height: 32px;
    background: red;
    border-radius: 3px;
}

.yellow-card {
    width: 25px;
    height: 32px;
    background: yellow;
    border-radius: 3px;
}

/* 5.7 Academy Score Ended Here */
/* 5.8 Academy Fixture Started Here */
.fixtures-item-wrapper {
    width: 100%;
    height: 120px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    margin-bottom: 20px;
}

.fixtures-item-wrapper .fixture-item-info {
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    align-items: center;
    width: 100%;
    height: 100%;
}

.fixtures-item-wrapper .fixture-item-info .tg-box {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.fixtures-item-wrapper .fixture-item-info .tg-box img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.fixtures-item-wrapper .fixture-item-info .tg-box h2 {
    margin-bottom: 0px;
    font-size: 20px;
    font-family: var(--theme-font);
    text-transform: uppercase;
}

.fixtures-item-wrapper .fixture-item-info .tg-box h4 {
    display: inline-block;
    width: 50px;
    margin-bottom: 0;
    position: relative;
    text-align: center;
    line-height: 50px;
    background: var(--theme-color);
    font-size: 24px;
    color: #fff;
    border-radius: 100px;
    transition: all .5s;
}

.fixtures-item-wrapper .fixture-item-info-hover {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 28px 20px 21px 30px;
    position: absolute;
    background: var(--theme-color);
    transform: translateX(-101%);
    transition: all 300ms ease-in-out;
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
}

.fixtures-item-wrapper .fixture-item-info-hover button {
    background: #fff;
    border: none;
    color: var(--black-color);
    text-transform: uppercase;
    font-family: var(--theme-font);
    padding: 5px 10px;
}

.fixtures-item-wrapper:hover .fixture-item-info-hover {
    transform: translateX(0);
}

/* 5.8 Academy Fixture Ended Here */
/* 6. Contact Us Started Here */
.contact-from-wrapper form {
    padding: 20px;
    border: 1px solid #fff;
}

.contact-section .container {
    display: flex;
    flex-direction: row;
}

.contact-section .contact-details-container {
    width: 40%;
}

.contact-section .contact-form-container {
    width: calc(50% + 400px);
    margin-right: -400px;
    margin-top: -93px;
    z-index: 2;
    padding: 25px 290px 25px 25px;
    background: var(--theme-color);
}

.contact-section .contact-form-container h2,
.contact-section .contact-form-container h4 {
    color: #fff;
}

.contact-section .contact-form-container h2:after {
    background: linear-gradient(89deg, #fff, transparent);
}

.contact-section .contact-details-item {
    border: 2px solid #710100;
    padding: 15px;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.contact-section .contact-details-item .contact-item-icon-wrapper h2 {
    font-size: 40px;
    color: var(--theme-color);
    margin-bottom: 0;
}

.contact-section .contact-details-item .contact-item-content-wrapper h2 {
    font-size: 20px;
    text-transform: uppercase;
    font-family: var(--theme-font);
    font-weight: 600;
    color: var(--theme-color);
}

.contact-section .contact-details-item .contact-item-content-wrapper p {
    font-size: 15px;
    margin-bottom: 0px;
}

.contact-section .contact-details-item .contact-item-content-wrapper p a {
    color: var(--black-color);
    text-decoration: none;
}

.contact-section .contact-details-container-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
}

.contact-from-wrapper .form-control {
    border-radius: 0px;
    height: 40px;
}

.contact-from-wrapper .form-select {
    border-radius: 0px;
    height: 40px;
}

.contact-from-wrapper .form-control:focus {
    box-shadow: none;
}

.contact-from-wrapper .home-btn-animation {
    border: 2px solid #fff;
    color: #fff;
}

.contact-from-wrapper .home-btn-animation::before {
    background: #fff;
}

.contact-from-wrapper .home-btn-animation:hover {
    color: var(--theme-color);
}

.google-map-container iframe {
    width: 100%;
}

/* 6. Contact Us Ended Here */
/* 7. Gallery Started Here */
.gallery-section ul.nav-tabs {
    border-bottom: none;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.gallery-section ul.nav-tabs li button {
    border: none;
    border: 1px solid #ddd !important;
    border-radius: 17px;
    padding: 5px 40px;
    background: #ddd;
    color: var(--theme-color);
    text-transform: uppercase;
    font-family: var(--theme-font);
    font-weight: 500;
    font-size: 16px;
}

.gallery-section ul.nav-tabs li button.active {
    color: #fff;
    background: var(--theme-color);
}

.gallery-section .image-grid-item {
    width: 100%;
    height: 220px;
    margin-bottom: 25px;
    position: relative;
    cursor: pointer;
}

.gallery-section .image-grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*  */
.gallery-section .image-grid-item::before {
    content: "";
    width: 50%;
    height: 50%;
    background-color: rgb(113 1 0 / 58%);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.gallery-section .image-grid-item::after {
    content: "";
    width: 50%;
    height: 50%;
    background-color: rgb(113 1 0 / 58%);
    position: absolute;
    top: 50%;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.gallery-section .image-grid-item .gallery_card_hover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 300ms linear 0s;
    flex-direction: column;
    gap: 20px;
}

.gallery-section .image-grid-item .gallery_card_hover::before {
    content: "";
    width: 50%;
    height: 50%;
    background-color: rgb(113 1 0 / 58%);
    position: absolute;
    top: 0;
    left: 50%;
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.gallery-section .image-grid-item .gallery_card_hover::after {
    content: "";
    width: 50%;
    height: 50%;
    background-color: rgb(113 1 0 / 58%);
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.gallery-section .image-grid-item:hover:before {
    left: 50%;
    opacity: 1;
    z-index: 1;
}

.gallery-section .image-grid-item:hover::after {
    top: 0;
    opacity: 1;
}

.gallery-section .image-grid-item:hover .gallery_card_hover {
    opacity: 1;
}

.gallery-section .image-grid-item:hover .gallery_card_hover::before {
    top: 50%;
    opacity: 1;
}

.gallery-section .image-grid-item:hover .gallery_card_hover::after {
    left: 0;
    opacity: 1;
}

.gallery-section .image-grid-item .gallery_card_hover h3 {
    color: #fff;
    z-index: 1;
    margin-bottom: 0px;
    font-size: 21px;
    font-family: var(--theme-font);
    background: linear-gradient(45deg, var(--theme-color), transparent);
    padding: 5px;
    position: absolute;
    width: 100%;
    bottom: 0;
}

.gallery-section .image-grid-item .gallery_card_hover button {
    border: none;
    background: #fff;
    z-index: 1;
    color: var(--theme-color);
    font-family: var(--theme-font);
    text-transform: uppercase;
    font-size: 13px;
    word-spacing: 4px;
    border-radius: 3px;
    font-weight: 600;
    opacity: 0;
    transition: 0.3s all ease-in-out;
}

.gallery-section .image-grid-item .gallery_card_hover button.playbutton {
    background: #00000082;
    color: #fff;
    font-size: 28px;
    padding: 0;
    padding-left: 7px;
    line-height: 28px;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-section .image-grid-item:hover button {
    opacity: 1;
}

.image-gallery-section .image-gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.image-gallery-section .image-gallery-container img {
    width: calc(33% - 15px);
    height: 250px;
    object-fit: cover;
}

/* 7. Gallery Ended Here */
/* 8. Amenities Started Here */
.amenities-section .amenities-intro-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.amenities-section .amenities-intro-container .amenities-intro-img-wrapper {
    flex: 1;
    height: 400px;
}

.amenities-section .amenities-intro-container .amenities-intro-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.amenities-section .amenities-intro-container .amenities-intro-content-wrapper {
    flex: 1;
}

.amenities-intro-container .amenities-intro-content-wrapper ul {
    font-size: 16px;
    padding-left: 0px;
    list-style-type: none;
}

.amenities-intro-container .amenities-intro-content-wrapper ul li {
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative;
}

.amenities-intro-container .amenities-intro-content-wrapper ul li::before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background: var(--theme-color);
    border-radius: 50%;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
}

.amenities-parallex-1 {
    background-image: url("https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomeTeam/IMG_3834-min.JPG");
    width: 100%;
    height: 500px;
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.amenities-parallex-1.home-video-parallex {
    background-image: url(../images/background/home-video.jpg);
    background-attachment: unset;
}

.amenities-parallex-1 .amenities-parallex-1-overlay {
    width: 100%;
    height: 100%;
    background: #0000006b;
    display: flex;
    justify-content: center;
    align-items: center;
}

.amenities-parallex-1 .amenities-parallex-1-overlay button {
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background: #71010069;
    color: #fff;
    padding-left: 10px;
    position: relative;
}

.amenities-facilities-item .amenities-facilities-icon-wrapper img {
    /* height: 80px;
    height: 80px; */
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 0.3s all ease-in-out;
}

.amenities-facilities-item:hover img {
    filter: grayscale(40%);

}

.amenities-facilities-item {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    background: #dddddd38;
}

.amenities-facilities-item .amenities-facilities-icon-wrapper {
    width: 100%;
    /* height: 120px; */
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.amenities-facilities-item .amenities-facilities-content-wrapper {
    padding: 10px;
    text-align: center;
}

.amenities-facilities-item .amenities-facilities-content-wrapper p {
    margin-bottom: 0px;
    font-size: 16px;
}

.amenities-facilities-item .amenities-facilities-content-wrapper h4 {
    font-size: 20px;
    text-transform: uppercase;
    font-family: var(--theme-font);
    color: var(--theme-color);
    font-weight: 600;
}

.amenities-modal-container .modal-header {
    position: absolute;
    right: -30px;
    background: #fff;
    border-radius: 0px 15px 15px 0;
    padding: 10px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 800px !important;
    }
}

.amenities-modal-container .modal-content .modal-body {
    padding: 5px;
    padding-bottom: 0px;
}

/* 8. Amenities Ended Here */
/* 9. Wall of Fame Started Here */
.gallery-section.wall-of-fame-section {
    background-image: url(../images/WallofFame/parallex-bg.png);
    width: 100%;
    min-height: 500px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}

.gallery-section.wall-of-fame-section .wall-of-fame-overlay {
    width: 100%;
    height: 100%;
    min-height: 500px;
    background: #0000006b;
}

/* .fame-parallex-1 {
    background-image: url(../images/WallofFame/parallex-bg.png);
    width: 100%;
    min-height: 500px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
} */

/* .fame-parallex-1 .fame-parallex-1-overlay {
    width: 100%;
    height: 100%;
    min-height: 500px;
    background: #0000006b;
} */

.fame-card-item {
    width: 80%;
}

.fame-card-item .fame-card-img-wrapper {
    width: 100%;
    height: 300px;
}

.fame-card-item .fame-card-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fame-card-item .fame-card-content-wrapper h2 {
    color: #fff;
}

/* 22-05-2023 */
.teams-flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 100px;
}

.teams-flex-container .teams-item {
    width: 18%;
    height: auto;
    position: relative;
    transition: all 0.6s ease-in-out;
    cursor: crosshair;
}

.teams-flex-container .teams-item .team-img-wrapper {
    flex: none;
    margin: 0 auto;
    max-width: 100%;
    height: 250px;
    filter: grayscale(1);
    pointer-events: none;
    transition: all 0.6s ease-in-out;
    z-index: 2;
    position: relative;
}

.teams-flex-container .teams-item .team-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.teams-flex-container .teams-item .team-bg {
    position: absolute;
    width: 30%;
    height: 30%;
    top: -12px;
    left: -12px;
    transition: all 0.6s ease-in-out;
    background: #dde20d;
    z-index: 1;
}

.teams-flex-container .teams-item .team-bg1 {
    position: absolute;
    width: 30%;
    height: 30%;
    bottom: -12px;
    right: -12px;
    transition: all 0.6s ease-in-out;
    background: url(https://res.cloudinary.com/ddconozc1/image/upload/v1659512699/Assets/Images/about-us/dotted-bg_qypqzw.png);
    background-position: bottom right;
    background-size: 112%;
    z-index: 1;
}

.teams-flex-container .teams-item h3 {
    margin: 0;
    line-height: 1;
    position: absolute;
    z-index: 1;
    font-size: 33px;
    writing-mode: vertical-rl;
    right: -2.5rem;
    top: 0px;
    -webkit-text-stroke: 1px #dde20d;
    -webkit-text-fill-color: transparent;
    color: transparent;
    transition: all 0.6s ease-in-out;
}

.teams-flex-container .teams-item h4 {
    height: 40px;
    bottom: -50px;
    top: auto;
    position: absolute;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 16px;
    font-family: var(--heading-font);
    transition: all 0.6s ease-in-out;
    color: #fff;
}

.teams-flex-container .teams-item:hover h3 {
    -webkit-text-fill-color: #dde20d;
    transform: translateY(30px);
    color: #dde20d;
}

.teams-flex-container .teams-item:hover .team-img-wrapper {
    filter: grayscale(0);
}

.teams-flex-container .teams-item:hover .team-bg {
    width: 70%;
    height: 70%;
}

.teams-flex-container .teams-item:hover .team-bg1 {
    width: calc(30% + 10px);
    height: calc(30% + 10px);
}

.teams-flex-container .teams-item:hover {
    transform: translateY(-10px);
}

.teams-flex-container .teams-item:hover h4 {
    transform: scale(1.1);
}

/* 9. Wall of Fame Ended Here */
/* 10. Careers Started Here */
.careers-section a {
    text-decoration: none;
}

.careers-section .vacancy-content {
    margin-bottom: 20px;
    border-radius: 2px;
    padding: 25px;
    border: 1px solid var(--theme-color);
    box-shadow: 0 0 10px #00000021;
    cursor: pointer;
}

.careers-section .vacancy-content span {
    color: var(--theme-color);
    margin-bottom: 10px;
    font-weight: 600;
    display: block;
    text-transform: uppercase;
}

.careers-section .vacancy-content h3 {
    font-size: 26px;
    font-weight: 600;
    color: var(--theme-color);
    font-family: var(--theme-font);
}

.careers-section .vacancy-content p {
    margin: 0 0 15px;
    word-wrap: break-word;
    color: #444;
    font-size: 16px;
}

.amenities-modal-container.careers-modal-container .modal-content {
    border-radius: 2px;
    background: #ddd;
    padding: 7px;
}

.amenities-modal-container.careers-modal-container .modal-header {
    background: #ddd;
    top: 0px;
}

.amenities-modal-container.careers-modal-container .modal-content .modal-body {
    padding: 15px 20px;
    background: #fff;
}

.amenities-modal-container.careers-modal-container .modal-dialog {
    max-width: 500px !important;
}

.amenities-modal-container.careers-modal-container .modal-dialog h3 {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 0 !important;
    color: var(--theme-color);
}

.amenities-modal-container.careers-modal-container .modal-dialog h2 {
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    line-height: 1.5;
    font-size: 20px;
    color: var(--theme-color);
}

.amenities-modal-container.careers-modal-container .modal-dialog p {
    margin-bottom: 0;
    font-size: 13px;
    text-align: center;
}

.amenities-modal-container.careers-modal-container .modal-dialog p a {
    color: var(--theme-color);
    text-decoration: none;
    font-weight: 700;
}

.amenities-modal-container.careers-modal-container .modal-dialog form {
    margin-top: 20px;
}

.amenities-modal-container.careers-modal-container .modal-dialog form .form-control {
    border-radius: 0px;
    height: 35px;
}

/* 10. Careers Ended Here */
/* 11. Press Page started here */
.press-section a {
    text-decoration: none;
}

.press-card-item {
    background: #f1f1f1;
    margin-bottom: 20px;
    cursor: pointer;
}

.press-card-item .press-card-img-wrapper img {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
}

.press-card-item .press-card-content-wrapper {
    padding: 10px;
}

.press-card-item .press-card-content-wrapper h3 {
    color: var(--theme-color);
    font-family: var(--theme-font);
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.press-card-item .press-card-content-wrapper p {
    font-size: 15px;
    margin-bottom: 0px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    width: -moz-fit-content;
    padding-bottom: 2px;
    margin-bottom: 6px;
    width: fit-content;
    color: #333;
    font-family: var(--heading-font);
}

.press-modal-container {
    text-align: center;
}

.press-modal-container img {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
}

.press-modal-container .modal-content {
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.press-modal-container .modal-content h2 {
    font-family: var(--theme-font);
    font-size: 27px;
    margin-top: 12px;
    color: var(--theme-color);

}

/* 11. Press Page ended here */
/* 12. Team Behind Team Started Here */

.academy-teams-section.advisory-board-team-container .academy-team-item {
    width: 80%;
    height: 300px;
    position: relative;
    margin-bottom: 85px;
    cursor: pointer;
}

.academy-teams-section.advisory-board-team-container .academy-team-item h2 {
    color: var(--theme-color);
    position: relative;
    right: unset;
    top: unset;
    font-size: 20px;
    letter-spacing: 1.2px;
    font-family: var(--heading-font);
    margin-top: 7px;
    text-transform: uppercase;
    writing-mode: horizontal-tb;
    margin-bottom: 5px;
}

.academy-teams-section.advisory-board-team-container .academy-team-item h3 {
    font-size: 12px;
}

.academy-teams-section.advisory-board-team-container .academy-team-item ul {
    position: absolute;
    top: 0;
    left: 100%;
    list-style-type: none;
    background: linear-gradient(237deg, #710100, #710100a1);
    padding: 5px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 35px;
    border-radius: 0 0 38px;
}

.academy-teams-section.advisory-board-team-container .academy-team-item ul li a {
    background: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.academy-teams-section.advisory-board-team-container .academy-team-item ul li a.footer-fb {
    color: #4267B2;
}

.academy-teams-section.advisory-board-team-container .academy-team-item ul li a.footer-insta {
    color: #bc2a8d;
}

.academy-teams-section.advisory-board-team-container .academy-team-item ul li a.footer-twitter {
    color: #1DA1F2;
}

.academy-teams-section.advisory-board-team-container .academy-team-item ul li a.footer-linkedIn {
    color: #0A66C2;
}

/* 12. Team Behind Team Ended Here */
/* 13. Why Snigmay started here */
.home-why-snigmay-section {
    background: url(../images//background//green-grass.jpg);
    width: 100%;
    height: 500px;
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-why-snigmay-section .why-snigmay-overlay {
    height: 100%;
    width: 100%;
    background: #090909a3;
}

/* 13. Why Snigmay ended here */
.home-why-snigmay-section .animated-ellipses {
    position: relative;
}

.home-why-snigmay-section .count-wrapper {
    position: absolute;
    top: 0;
    left: 2rem;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-why-snigmay-section .count-wrapper label {
    display: block;
    font-size: 9rem;
    padding-bottom: 5px;
    padding-left: 5px;
    font-weight: 700;
    background-image: linear-gradient(115deg, #660302 0, #710100 25%, #f7fc00 75%, #710100 100%);
    background-size: 100%;
    background-repeat: repeat;
    background-position: 0 0;
    background-attachment: scroll;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-why-snigmay-section .animated-ellipses .text-reasons {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    min-height: 13rem;
}

.home-why-snigmay-section .animated-ellipses h3 {
    font-size: 16px;
    letter-spacing: -0.025em;
    line-height: 1;
    border-radius: 4px;
    margin-left: 9rem;
    padding: 0.7rem 1.2rem 0.7rem 5.3rem;
    opacity: 0.9;
    z-index: 1;
}

.home-why-snigmay-section .animated-ellipses h3:first-child {
    background: linear-gradient(120deg, #d9c300 10%, #700100 60%);
}

.home-why-snigmay-section .animated-ellipses h3:nth-child(2) {
    background: linear-gradient(120deg, #6e0100b5 10%, #553939 60%);
}

.home-why-snigmay-section .animated-ellipses h3:nth-child(3) {
    background: linear-gradient(120deg, #e7de00 10%, #710100 60%);
    opacity: 1;
}

/*  */
.home-why-snigmay-section .animated-ellipses .ellipse {
    width: 12rem;
    height: 12rem;
    left: 2rem;
    border-radius: 50%;
    border: 1px solid #fff;
}

.animated-ellipses .ellipse {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    animation: ellipsedPulse 4s infinite linear 4s;
}

.home-why-snigmay-section .animated-ellipses .ellipse:nth-child(2) {
    animation-delay: 1s;
}

.home-why-snigmay-section .animated-ellipses .ellipse:nth-child(3) {
    animation-delay: 2s;
}

.home-why-snigmay-section .animated-ellipses .ellipse:nth-child(4) {
    animation-delay: 3s;
}

@keyframes ellipsedPulse {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    25% {
        opacity: 0.4;
        transform: scale(1.2);
    }

    50% {
        opacity: 0.2;
        transform: scale(1.4);
    }

    75% {
        opacity: 0.1;
        transform: scale(1.6);
    }

    100% {
        opacity: 0;
        transform: scale(1.8);
    }
}

.home-why-snigmay-section .why-snigmay-container h3 {
    font-size: 19px;
    font-family: var(--theme-font);
    text-transform: uppercase;
    color: #fff;
}

.home-why-snigmay-section .why-snigmay-container ul {
    font-size: 18px;
    padding-left: 0px;
    list-style-type: none;
    column-count: 2;
    margin-top: -20px;
}

.home-why-snigmay-section .why-snigmay-container ul li {
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative;
    color: #fff;
}

.home-why-snigmay-section .why-snigmay-container ul li::before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background: #b3ba3a;
    border-radius: 50%;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
}

/*  */
.tech-team-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}

.tech-team-container .tech-team-item {
    width: calc(20% - 15px);
}

.tech-team-container .tech-team-item .team-item-wrapper {
    margin-bottom: 0px;
}

/*  */
/* csr started here */
.csr-intro-section .headling-wrapper {
    margin-bottom: 0px;
}

.csr-intro-section .csr-intro-item {
    background: #fff;
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.csr-intro-section .csr-intro-item .icon-wrapper {
    font-size: 43px;
    background: var(--theme-color);
    width: 75px;
    height: 75px;
    border-radius: 50%;
    color: #fff;
}

.csr-intro-section .csr-intro-item .content-wrapper h2 {
    font-size: 20px;
    text-transform: uppercase;
    color: var(--theme-color);
    font-family: var(--theme-font);
}

.csr-overview-right {
    height: 335px;
    width: 100%;
    background: var(--theme-color);
    padding: 0px;
}

.overview-right h3 {
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    padding-top: 20px;
    margin-bottom: 0px;
}

.overview-right ul {
    padding: 28px 0 0 0;
    list-style-type: none;
}

.overview-right ul li {
    float: left;
    width: 33%;
    padding: 12px 0;
    border-right: 1px solid #88888861;
    border-bottom: 1px solid #88888861;
    min-height: 120px;
}

.overview-right ul li aside {
    margin: 0 auto;
    text-align: center;
    margin: 0px;
    padding: 0px;
}

.overview-right ul li p {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400;
    background-color: transparent;
    margin: 0px 0 0 0;
    padding: 7px 0 0 0;
    text-align: center;
    color: #fff;
}

.overview-right ul li aside img {
    margin: 0px;
    padding: 0px;
    width: 55px;
    height: 55px;
}

.overview-right ul li:nth-child(3) {
    border-right: 0px;
}

.overview-right ul li:nth-child(4) {
    border-bottom: 0px;
}

.overview-right ul li:nth-child(5) {
    border-bottom: 0px;
}

.overview-right ul li:nth-child(6) {
    border-right: 0px;
    border-bottom: 0px;
}

.amenities-parallex-1.csr-parallex {
    background: url(../images/background/csr-bg.jpg);
    width: 100%;
    height: 500px;
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.amenities-parallex-1 .amenities-parallex-1-overlay button::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 500px;
    animation: 2s ease 0s normal none infinite running playbutton1;
}

.amenities-parallex-1 .amenities-parallex-1-overlay button::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 500px;
    animation: 2s ease 0s normal none infinite running playbutton;
}

/* csr ended here */
/* login page started */
.login-page-section {
    width: 100%;
    height: 90vh;
    background: url(../images/background/login.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.login-page-section-overlay {
    width: 100%;
    height: 100%;
    background: #0000009e;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form-wrapper {
    width: 450px;
    background: #ffffffc2;
    padding: 25px;
    border-radius: 5px;
}

.login-form-wrapper h1 {
    font-size: 23px;
    font-family: var(--theme-font);
    font-weight: 600;
    color: var(--theme-color);
    margin-bottom: 9px;
}

.login-form-wrapper form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login-form-wrapper input {
    width: 100%;
    height: 40px;
    border: 1px solid #abababdd;
    padding: 5px 10px;
    font-size: 20px;
    font-family: var(--josefin-font);
    font-weight: 400;
}

.login-form-wrapper input:focus-visible {
    box-shadow: none;
    border-color: #710100;
    outline: none;
}

.login-form-wrapper button {
    width: 100%;
    height: 40px;
    border: none;
    background: var(--theme-color);
    color: #fff;
    font-family: var(--theme-font);
    font-size: 20px;
    margin-top: 15;
}

/* login page ended */
/* scroll bar started here */
::-webkit-scrollbar {
    width: 4px;
    height: 7px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #4f0504, transparent);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #f17201;
}

/* scroll bar ended here */

/* confirmation box started here */
.confirm-box-modal .modal-dialog {
    max-width: 550px !important;
}

.confirm-box-modal .modal-dialog .confirm-decline {
    gap: 10px;
    display: flex;
    justify-content: center;
}

.confirm-box-modal .modal-dialog h2 {
    font-family: var(--theme-font);
    font-size: 25px;
    color: var(--theme-color);
}

.confirm-box-modal .modal-dialog p {
    font-size: 18px;
}

.table-button-container.admin-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}

.tech-team-container.home-banner-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    gap: 20px;
}

.tech-team-container.home-banner-container .tech-team-item {
    width: unset;
}

/* confirmation box ended here */